import React from "react";
import "./Members.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function Members() {
  return (
    <div>
      <h1 className="h1">Meet Our Team</h1>
      <br />
      <br />
      <h1 className="h1" id="m1">
        2K17
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Abhay Kumar</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Aditi</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Alka Tirkey</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Anuj Rana</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Aryan Purty</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Chawli</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Hunny Gupta </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Kishlay Kumar Mahato </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Manas Kumar Sah</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Milind Singh</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Monika suman Tigga </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nutan Sinku </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Priyanka Kumari </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Rohan Kumar</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankha Adhya</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Saurav Tiway</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankha Adhya</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Saurav Tiway</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Shreya Gupta</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Subham Jha</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Yashoda Kumari</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Adarsh Kumar</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Aditi Sinha</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Aman Kumar Singh</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Ankur Minz</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ayushi Gupta</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Daisy Murmu</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Kavita Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Mahima Singh</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Manisha Murmu</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Monika Mardi</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nirupa Manda</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Pallavi Jha</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Riya Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankat Munda</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Sanyam Prabhat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Shiwani Verma</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Sneha Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sourav Kumar Chouhan</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="h1" id="m2">
        2K18
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">SHIMLEE GHOSH</h5>
                <p>President</p>
                <p>Branch: Computer Science Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Abhinav Kumar Sah</h5>
                <p>Vice-President</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Roshan Kumar Rajak </h5>
                <p>Secretary</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Krishna Modi</h5>
                <p>Joint-Secretary</p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Samanta Ray</h5>
                <p>Joint-Secretary</p>
                <p>Branch: Metallurgy Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ankit Anand</h5>
                <p>Treasurer</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">SHITAL SAKSHI</h5>
                <p>Joint-Treasurer</p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title3">Rashmi Priya</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ananya Kesari</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Gaurav Nayan</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pranjal Ghosh</h5>
                <p>Meeting Head</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Kanchan Kumari</h5>
                <p>Meeting Head</p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Lucky Anand</h5>
                <p>Survey Heads </p>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham Kr. Jha</h5>
                <p>Survey Heads </p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Somya Vats</h5>
                <p>P.R.O </p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rewti Raman Sahu</h5>
                <p>P.R.O </p>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham Sahu</h5>
                <p>Alumni Incharge </p>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivam Singh</h5>
                <p>Alumni Incharge </p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ashutosh Choubey</h5>
                <p>Inventory Head </p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Neerav Singh</h5>
                <p>Media Head </p>
                <p>Branch: Information Technology</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rahul Kumar</h5>
                <p>Media Head </p>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Arvind Borda</h5>
                <p>Planning and Execution Head </p>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Kumar Anupam</h5>
                <p>Planning and Execution Head </p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivam Raj</h5>
                <p>Program Coordinator </p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shalu Rani</h5>
                <p>Program Coordinator </p>
                <p>Branch: Computer Science and Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Indrajit Chatterjee</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">SWATI KUMARI SINGH</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">GAUTAM KUMAR</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pradeep Mohli</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ravi Kumar Singh</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shahbaj Alam</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">SHUBHAM KUMAR JHA</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vishal Kumbhakar</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sumit Kumar </h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">ABHIJEET BHAGAT </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Chandra Prakash</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">shishir kumar </h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Keshu Ranjan</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">MD SADIQUE </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Priyanka Bharti</h5>
                <p>Branch: Computer Science and Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rahul Kumar Saw </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">MD MUSAHID</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vivek Kumar Munda </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sumeet Vishwakarma</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Anjali Kumari </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Nisha Manjhi</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Apoorv Ashish Parmar </h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shruti Kumari</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">HRITIK SINGH ANAND </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shruti Kumari</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Payal singh</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">REETA KUMARI BEDIA</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Upasana Bharti</h5>
                <p>Branch: Information and Technology</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Aditya Tiwari</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Abhishek Kumar Jha</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <h1 className="h1" id="m3">
        2K19
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Raj Hansh Raj </h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Yogesh Kumar</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham Kumar</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Prahul kumar</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rohan Anand</h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Harshit Pallove </h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Nitish kumar</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sunny kumar </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Akash Mandal </h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Amit kumar</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivam Kumar </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Kumar sanu</h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Suraj Pramanik </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Piyush Kumar</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pavitra Kumar </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Anjali Kumari </h5>
                <p>Branch: Information Technology</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sagar Mishra </h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vicky Kumar</h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rahul ranjan </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Khushi Grover</h5>
                <p>Branch: Electronics and Communication Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Nikki kumari </h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Binod Murmu </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Budhram Hembram </h5>
                <p>Branch: Electronics and Communication Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ajay kumar</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Geet kumar </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivnandan </h5>
                <p>Branch: Computer Science and Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Dhiraj Pandey </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Nadeem Rehman</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Anjali Kumari </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <h1 className="h1" id="m4">
        2K20
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Gorella Kumar </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Anurag Kr Soni</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Manish Kumar</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Om Prakash</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Abhishek Kr Shukla</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Priyanka Sharma </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Adarsh Kumar</h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Divya Kacchap</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ashmi singh </h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vivek Kumar</h5>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Priyanshu Kr keshri </h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sumit Pradhan</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Manisha Kumari </h5>
                <p>Branch: Electronics and Communication Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pratyush Kumar</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sneha Kumari </h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Gayetri Murmu </h5>
                <p>Branch: Electronics and Communication Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sammi Kumari </h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Satyam Siromani</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Mukund Parasher </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Suraj Kumar</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Mohit Kumar saw</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ankit Kishore Khalkho </h5>
                <p>Branch: Information Technology</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Dipesh Kr Jaiswal </h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Abhishek Kumar</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Gaurav Swansi </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham barnwal </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Members;
