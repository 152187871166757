import React from "react";
import "./Members.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function M18() {
  return (
    <div>
      <h1 className="h1">Meet Our Team</h1>
      <br />
      <br />{" "}
      <h1 className="h1" id="m2">
        2K18
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">SHIMLEE GHOSH</h5>
                <p>President</p>
                <p>Branch: Computer Science Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Abhinav Kumar Sah</h5>
                <p>Vice-President</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Roshan Kumar Rajak </h5>
                <p>Secretary</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Krishna Modi</h5>
                <p>Joint-Secretary</p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Samanta Ray</h5>
                <p>Joint-Secretary</p>
                <p>Branch: Metallurgy Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ankit Anand</h5>
                <p>Treasurer</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">SHITAL SAKSHI</h5>
                <p>Joint-Treasurer</p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title3">Rashmi Priya</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ananya Kesari</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Gaurav Nayan</h5>
                <p>Technical Head</p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pranjal Ghosh</h5>
                <p>Meeting Head</p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Kanchan Kumari</h5>
                <p>Meeting Head</p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Lucky Anand</h5>
                <p>Survey Heads </p>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham Kr. Jha</h5>
                <p>Survey Heads </p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Somya Vats</h5>
                <p>P.R.O </p>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rewti Raman Sahu</h5>
                <p>P.R.O </p>
                <p>Branch: Civil Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shubham Sahu</h5>
                <p>Alumni Incharge </p>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivam Singh</h5>
                <p>Alumni Incharge </p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ashutosh Choubey</h5>
                <p>Inventory Head </p>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Neerav Singh</h5>
                <p>Media Head </p>
                <p>Branch: Information Technology</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rahul Kumar</h5>
                <p>Media Head </p>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Arvind Borda</h5>
                <p>Planning and Execution Head </p>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Kumar Anupam</h5>
                <p>Planning and Execution Head </p>
                <p>Branch: Electronics and Communication</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shivam Raj</h5>
                <p>Program Coordinator </p>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shalu Rani</h5>
                <p>Program Coordinator </p>
                <p>Branch: Computer Science and Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Indrajit Chatterjee</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">SWATI KUMARI SINGH</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">GAUTAM KUMAR</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Pradeep Mohli</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Ravi Kumar Singh</h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shahbaj Alam</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">SHUBHAM KUMAR JHA</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vishal Kumbhakar</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sumit Kumar </h5>
                <p>Branch: Metallurgical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">ABHIJEET BHAGAT </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Chandra Prakash</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">shishir kumar </h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Keshu Ranjan</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">MD SADIQUE </h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Priyanka Bharti</h5>
                <p>Branch: Computer Science and Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Rahul Kumar Saw </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">MD MUSAHID</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Vivek Kumar Munda </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Sumeet Vishwakarma</h5>
                <p>Branch: Chemical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Anjali Kumari </h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Nisha Manjhi</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Apoorv Ashish Parmar </h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shruti Kumari</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">HRITIK SINGH ANAND </h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Shruti Kumari</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Payal singh</h5>
                <p>Branch: Production Engineering</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">REETA KUMARI BEDIA</h5>
                <p>Branch: Electrical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Upasana Bharti</h5>
                <p>Branch: Information Technology</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Aditya Tiwari</h5>
                <p>Branch: Mining Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title2">Abhishek Kumar Jha</h5>
                <p>Branch: Mechanical Engineering</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default M18;
