import React from "react";
import "./Members.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function M17() {
  return (
    <div>
      <h1 className="h1">Meet Our Team</h1>
      <br />
      <br />
      <h1 className="h1" id="m1">
        2K17
      </h1>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Abhay Kumar</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Aditi</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Alka Tirkey</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Anuj Rana</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Aryan Purty</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Chawli</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Hunny Gupta </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Kishlay Kumar Mahato </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Manas Kumar Sah</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Milind Singh</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Monika suman Tigga </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nutan Sinku </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Priyanka Kumari </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Rohan Kumar</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankha Adhya</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Saurav Tiway</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankha Adhya</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Saurav Tiway</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Shreya Gupta</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Subham Jha</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Yashoda Kumari</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Adarsh Kumar</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Aditi Sinha</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Aman Kumar Singh</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Ankur Minz</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ayushi Gupta</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Daisy Murmu</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Kavita Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Mahima Singh</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Manisha Murmu</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Monika Mardi</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nirupa Manda</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Pallavi Jha</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Riya Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sankat Munda</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Sanyam Prabhat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Shiwani Verma</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Sneha Kumari</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row mem ">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div class="container">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title1">Sourav Kumar Chouhan</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default M17;
