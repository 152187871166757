import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Routelinkas from "./Routelinkas";

function App() {
  return (
    <div>
      <Routelinkas></Routelinkas>
    </div>
  );
}

export default App;
